import { CollectionApi } from '@jumpcloud-ap/collection-api';

class PartnersApi extends CollectionApi {
  constructor() {
    super('/api/v2/partners', {
      skip: 0,
      limit: 50,
      sort: 'name',
    });
  }
}

export default new PartnersApi();
