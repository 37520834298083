import { setupFeatureFlags } from '@jumpcloud/ui-feature-flags';

export const {
  FeatureFlagMixin,
  FeatureFlagsModule,
  initializeFlags,
  useFeatureFlags,
} = setupFeatureFlags([
  {
    localName: 'deviceHealthMonitoring',
    sourceId: '2024-03-01-monitoring',
  },
  {
    localName: 'passwordManager',
    sourceId: '2022-05-25-Password-Manager-Toggle',
  },
  {
    localName: 'saasManagement',
    sourceId: '2024-06-12-saas-management',
  },
  {
    localName: 'sidenavGoToButton',
    sourceId: '2024-10-01-sidenav-goto',
  },
  {
    localName: 'synthesizedReports',
    sourceId: '2021-04-01-synthesized-reports',
  },
  {
    localName: 'workflowsManagement',
    sourceId: '2024-07-15-request-approval-workflow',
  },
]);
