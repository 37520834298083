<template>
  <nav
    v-if="!shouldHideSideNav"
    :class="[
      $style.nav,
      {[$style.navExpanded]: !collapsed},
      {[$style.productTourActive]: showProductTour},
    ]"
    :data-test-id="$testId('navContainer')"
  >
    <div :class="$style.navPanelBodyContainer">
      <header :class="$style.navPanelHeader">
        <Link
          :class="$style.logoLink"
          title="JumpCloud Directory Platform"
          :to="logoLinkTo"
          @click="trackNavClicked(logoLink.href)"
        >
          <JumpcloudBrandmark
            darkBg
            :scale="1.75"
            :type="collapsedLogo"
          />
        </Link>
      </header>
      <TrialRibbon
        :class="[{[$style.trialRibbonWithGoToButton]: showGoToButton}]"
        :isNavCollapsed="collapsed"
      />
      <GoToButton
        v-if="showGoToButton"
        :class="$style.goToButton"
        :isNavCollapsed="collapsed"
        :isShortcutDisabled="showProductTour"
      />
      <ProductTourController />
      <div
        ref="navPanelBody"
        :class="$style.navPanelBody"
      >
        <div :class="[$style.navPanelSection, $style.gettingStartedSection]">
          <ul :class="$style.linkList">
            <li :class="$style.linkListItem">
              <GetStartedLink
                v-if="filterLinks(getStartedLink)"
                :activeLink="activeLink"
                :isActive="isActive(getStartedLink.href)"
                :item="getStartedLink"
                :panelExpanded="!collapsed"
              />
              <NavPanelLink
                v-if="filterLinks(homeLink)"
                :active="isActive(homeLink.href)"
                :activeLink="activeLink"
                :class="$style.homeLink"
                :data-test-id="$testId('homeLink')"
                :item="homeLink"
                :panelExpanded="!collapsed"
              />
            </li>
          </ul>
        </div>
        <NavPanelSection
          v-for="navPanelSection in navPanelSections"
          :key="navPanelSection.title"
          :activeLink="activeLink"
          :class="$style.navPanelSection"
          :isActive="isActive"
          :links="navPanelSection.links"
          :panelExpanded="!collapsed"
          :title="navPanelSection.title"
          @nav-panel-section-collapsed="handleSectionCollapsed"
        />
      </div>
    </div>
    <footer :class="$style.navFooter">
      <ul :class="$style.linkList">
        <li
          v-if="orgCreatedDate"
          :class="$style.linkListItem"
        >
          <IntercomChat
            :expanded="!collapsed"
            :imageComponent="chatLink.imageComponent"
            :orgCreatedDate="orgCreatedDate"
          />
        </li>
        <li
          v-if="!isLimitedRole"
          :class="$style.linkListItem"
        >
          <NavPanelLink
            :active="isActive(settingsLink.href)"
            :activeLink="activeLink"
            :data-test-id="$testId('settingsLink')"
            :highlightProductTour="showProductTourPopover"
            :item="settingsLink"
            :panelExpanded="!collapsed"
          >
            <template
              v-if="showProductTourPopover"
              v-slot:popover
            >
              <NavPanelProductTour
                :expanded="!collapsed"
              />
            </template>
          </NavPanelLink>
        </li>
        <li
          v-if="showAccountLink"
          :class="$style.linkListItem"
        >
          <NavPanelLink
            :active="isActive(accountLink.href)"
            :activeLink="activeLink"
            :data-test-id="$testId('accountLink')"
            :item="accountLink"
            :panelExpanded="!collapsed"
          />
        </li>
        <li :class="[$style.linkListItem, $style.collapseItem]">
          <NavPanelLink
            :data-test-id="$testId('collapseNavButton')"
            isButton
            :item="collapseItem"
            :panelExpanded="!collapsed"
            secondaryColor
            @click.capture="handleToggleClick"
          />
        </li>
      </ul>
    </footer>
  </nav>
</template>

<script>
import { JumpcloudBrandmark, Link } from '@jumpcloud/ui-components';
import { TrialRibbon, entitlementModuleName } from '@jumpcloud-ap/time-based-trial-vue3';
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import { mapActions, mapGetters, mapState } from 'vuex';
import { removeHash } from '@/utils/removeHash';
import GetStartedLink from '@/components/GetStartedLink.vue';
import GoToButton from '@/components/GoToButton.vue';
import IconCollapse from '@/img/icons/nav/icon-nav-collapse.svg';
import IntercomChat from '@/components/IntercomChat.vue';
import LocalStorageService from '@/utils/LocalStorageService';
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkList from '@/components/NavPanelLinkList';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';
import NavPanelProductTour from '@/components/NavPanelProductTour.vue';
import NavPanelSection from '@/components/NavPanelSection.vue';
import OrganizationsApi from '@/api/OrganizationsApi';
import ProductTourController from '@/components/ProductTour/ProductTourController.vue';

const {
  accountLink,
  activeDirectoryLink,
  chatLink,
  cloudDirectoriesLink,
  commandsLink,
  conditionalListsLink,
  conditionalPoliciesLink,
  configurationsLink,
  configurationGroupsLink,
  deviceGroupsLink,
  deviceTrustLink,
  devicesLink,
  directoryInsightsLink,
  getStartedLink,
  homeLink,
  hrDirectoriesLink,
  identityProvidersLink,
  ldapLink,
  mfaLink,
  mdmLink,
  passwordManagerLink,
  softwareAppsLink,
  radiusLink,
  reportsLink,
  saasManagementLink,
  settingsLink,
  ssoAppsLink,
  userGroupsLink,
  usersLink,
  WorkflowsLink,
} = NavPanelLinkList;

const navPanelCollapsed = 'jcNavPanelCollapsed';
const navPanelSectionsCollapsed = 'jcNavPanelSectionsCollapsed';

export default {
  name: 'NavPanel',

  components: {
    GetStartedLink,
    GoToButton,
    IntercomChat,
    JumpcloudBrandmark,
    Link,
    NavPanelLink,
    NavPanelProductTour,
    NavPanelSection,
    ProductTourController,
    TrialRibbon,
  },

  data: () => ({
    accountLink,
    chatLink,
    collapsed: false,
    getStartedLink,
    homeLink,
    orgCreatedDate: '',
    sectionCollapsed: {},
    settingsLink,
  }),

  computed: {
    ...mapState('ProductTourModule', [
      'showProductTour',
    ]),

    ...mapGetters('ProductTourModule', [
      'getProductTourActiveLink',
    ]),

    ...mapGetters('RouteInfoModule', {
      activeLink: 'fullPath',
      routeInfoMeta: 'meta',
    }),

    ...mapGetters('FeatureFlagsModule', ['betaFeatures']),

    ...mapGetters('PartnersModule', ['hasActiveCrowdStrikeLink']),

    ...mapGetters('CoreUserInfoModule', [
      'hasBilling',
      'isAdminWithBilling',
      'isAnMspOrChild',
      'isBillingOnly',
      'isCommandRunner',
      'isFirstSession',
      'isPrivilegedAccessAdmin',
      'isProvider',
      'orgId',
    ]),

    ...mapGetters('TargetedPromoModule', [
      'currentPromotedFeatureId',
    ]),

    collapseItem() {
      return new NavPanelLinkListItem({
        imageComponent: IconCollapse,
        title: this.collapsedLabel,
      });
    },

    collapsedLabel() {
      const collapsedLabel = this.collapsed ? 'Expand' : 'Collapse';
      return `${collapsedLabel} Menu`;
    },

    collapsedLogo() {
      return this.collapsed ? 'jcLogo' : 'jcLogoWordmark';
    },

    deviceManagementLinks() {
      return [
        devicesLink,
        deviceGroupsLink,
        configurationsLink,
        configurationGroupsLink,
        commandsLink,
        mdmLink,
        softwareAppsLink,
      ];
    },

    directoryIntegrationLinks() {
      const directoryIntegrationLinks = [
        activeDirectoryLink,
        cloudDirectoriesLink,
        hrDirectoriesLink,
        identityProvidersLink,
      ];

      return directoryIntegrationLinks;
    },

    insightsLinks() {
      return [
        directoryInsightsLink,
        reportsLink,
      ];
    },

    isLimitedRole() {
      return this.isCommandRunner || this.isBillingOnly || this.isPrivilegedAccessAdmin;
    },

    logoLink() {
      return homeLink;
    },

    logoLinkTo() {
      const { href = '' } = this.logoLink;

      return removeHash(href);
    },

    navPanelSections() {
      const sections = [
        {
          links: this.userManagementLinks,
          title: 'User Management',
        },
        {
          links: this.userAuthenticationLinks,
          title: 'User Authentication',
        },
        {
          links: this.deviceManagementLinks,
          title: 'Device Management',
        },
        {
          links: this.directoryIntegrationLinks,
          title: 'Directory Integrations',
        },
        {
          links: this.securityManagementLinks,
          title: 'Security Management',
        },
        {
          links: this.insightsLinks,
          title: 'Insights',
        },
      ];

      // remove links hidden by Feature Flags then empty sections.
      return sections
        .map(section => ({
          ...section,
          links: section.links.filter(this.filterLinks),
        }))
        .filter(section => section.links.length > 0);
    },

    securityManagementLinks() {
      return [
        conditionalPoliciesLink,
        deviceTrustLink,
        conditionalListsLink,
        mfaLink,
        saasManagementLink,
        WorkflowsLink,
      ];
    },

    shouldHideSideNav() {
      return this.routeInfoMeta.shouldHideSideNav;
    },

    showAccountLink() {
      return this.hasBilling
        // is Provider checks the provider user (i.e. the head MTP admin)
        && !this.isProvider
        && !this.isAnMspOrChild
        && this.filterLinksByFeatureFlag(this.accountLink);
    },

    showGoToButton() {
      return this.$hasFeatureFlag('sidenavGoToButton');
    },

    showProductTourPopover() {
      return this.showProductTour && this.settingsLink === this.getProductTourActiveLink;
    },

    userAuthenticationLinks() {
      return [
        ldapLink,
        radiusLink,
        ssoAppsLink,
        passwordManagerLink,
      ];
    },

    userManagementLinks() {
      return [
        usersLink,
        userGroupsLink,
      ];
    },

  },

  watch: {
    currentPromotedFeatureId(newValue) {
      if (newValue) {
        this.scrollToPromotedNavLink();
      }
    },

    showProductTour(newValue) {
      if (newValue) {
        this.collapsed = false;
      } else {
        this.collapsed = LocalStorageService.getValue(navPanelCollapsed);
      }
    },
  },

  mounted() {
    this.updateCollapse();

    this.handlePartnersSectionCollapse();

    if (this.currentPromotedFeatureId) {
      this.scrollToPromotedNavLink();
    }
    this.fetchOrgSettings();
    this.fetchPartnerDetails();
    this.fetchEntitlement();
  },

  methods: {
    ...mapActions('PartnersModule', ['fetchPartners']),

    ...mapActions(entitlementModuleName, [
      'fetchOrgEntitlement',
    ]),

    async fetchEntitlement() {
      try {
        await this.fetchOrgEntitlement(this.orgId);
      } catch (error) {
        // no-op if the entitlements are not fetched
      }
    },

    async fetchOrgSettings() {
      try {
        const { created } = await OrganizationsApi.getOrganizationDetails(this.orgId);
        this.orgCreatedDate = created;
      } catch (error) {
        // no-op if the org is not fetched
      }
    },

    async fetchPartnerDetails() {
      try {
        await this.fetchPartners();
      } catch (error) {
        // no-op if the partners are not fetched
      }
    },

    filterLinks(link) {
      return this.filterLinksByFeatureFlag(link) && this.filterLinksForLimitedRoles(link);
    },

    filterLinksByFeatureFlag(link) {
      if (link.featureFlag !== undefined) {
        // the bang flag is a quick solution for adding the new tag to an existing link
        // there is probably a better long-term solution if we continue to tag existing links
        const isFlaggedForRemoval = link.featureFlag.startsWith('!');
        return isFlaggedForRemoval
          ? !this.betaFeatures[link.featureFlag.slice(1)]
          : !!this.betaFeatures[link.featureFlag];
      }
      return true;
    },

    filterLinksForLimitedRoles(link) {
      if (this.isCommandRunner) {
        return link.showForCommandRunners || false;
      }

      return !this.isBillingOnly && !this.isPrivilegedAccessAdmin;
    },

    handlePartnersSectionCollapse() {
      if (this.isFirstSession && this.hasActiveCrowdStrikeLink) {
        const navPanelSectionCollapseState = {};
        this.navPanelSections.forEach((section) => {
          if (section.title === 'Device Management') {
            navPanelSectionCollapseState[section.title] = false;
          } else {
            navPanelSectionCollapseState[section.title] = true;
          }
        });

        LocalStorageService.setItem(
          navPanelSectionsCollapsed, JSON.stringify(navPanelSectionCollapseState),
        );
      }
    },

    handleSectionCollapsed(value) {
      this.sectionCollapsed = value;
    },

    handleToggleClick() {
      this.collapsed = !this.collapsed;
      const action = this.collapsed ? 'Collapsed' : 'Expanded';

      UiEvents.triggerButtonClicked({
        description: `Nav Panel ${action}`,
        page: this.$page,
        section: 'footer',
        region: 'nav-panel',
        text: `${action} Menu`,
        value: `Panel ${action}`,
      });

      LocalStorageService.setItem(navPanelCollapsed, this.collapsed);
    },

    isActive(href) {
      if (href) {
        const link = this.activeLink;
        const { hash: locationHash } = window.location;

        if (href.includes(link) && link !== '') {
          return true;
        }

        if (locationHash.includes(href)) {
          return true;
        }
      }
      return false;
    },

    scrollToPromotedNavLink() {
      const navLink = document.querySelector(`[data-promo-id="${this.currentPromotedFeatureId}"]`);

      this.$nextTick().then(() => {
        navLink?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      });
    },

    trackNavClicked(href) {
      const toHref = href.replace('#/', '');
      UiEvents.triggerNavItemClicked({
        description: 'Clicked JumpCloud logo at top of nav panel',
        fromUrl: this.activeLink,
        toUrl: toHref,
        page: this.$page,
        text: 'Jumpcloud (logo)',
      });
    },

    updateCollapse() {
      this.collapsed = LocalStorageService.getValue(navPanelCollapsed);
    },
  },
};
</script>

<style module>
.nav {
  --nav-spacer: 14px;
  --scrollbarBg: var(--jcGrey900);
  --thumbBg: var(--jcGrey300);
  --navPanelPadding: 18px;

  background: var(--jc-color-secondary-stroke-default);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  transition: all var(--jc-transition-duration) var(--jc-transition-function);
  width: 76px;
  z-index: 200;
}

.navPanelHeader {
  line-height: 1;
  margin: 14px 0;
  max-height: 24px;
  padding: 0 16px;
}

.trialRibbonWithGoToButton {
  max-height: 2em;
}

.goToButton {
  margin-bottom: var(--jc-spacer-small);
}

.goToButton::after {
  background: radial-gradient(ellipse at center, var(--jcGrey400) 0%, rgba(0, 0, 0, 0) 65%);
  content: '';
  display: block;
  height: 1px;
  margin-top: var(--jc-spacer-small);
  width: 100%;
}

.logoLink.logoLink.logoLink {
  border-bottom: none;
  display: block;

  /* prevents the logo from shifting sizes navigating between legacy styles and non-legacy styles */
  font-size: 13px;
  max-height: 24px;
  transition: all var(--jc-transition-duration) var(--jc-transition-function);
}

.logoLink.logoLink.logoLink:hover {
  border-bottom: none;
}

.logoLink.logoLink.logoLink:focus {
  box-shadow: none;
}

ul.linkList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: auto;
}

.linkList .homeLink {
  margin-bottom: var(--jc-size-1p25);
}

.navPanelBodyContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.navPanelBody {
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: var(--jc-spacer-small);
  scrollbar-color: var(--thumbBg) var(--scrollbarBg);
  scrollbar-width: thin;
}

.navPanelBody::-webkit-scrollbar {
  width: 11px;
}

.navPanelBody::-webkit-scrollbar-track {
  background: var(--scrollbarBg);
}

.navPanelBody::-webkit-scrollbar-thumb {
  background-color: var(--thumbBg);
  border: 3px solid var(--scrollbarBg);
  border-radius: 6px;
}

.navFooter {
  margin: 0;
  padding: 0 4px var(--nav-spacer);
  position: relative;
}

.navFooter::before {
  background: radial-gradient(ellipse at center, var(--jcGrey400) 0%, rgba(0, 0, 0, 0) 65%);
  content: '';
  display: block;
  height: 1px;
  margin: var(--nav-spacer) 0;
  width: 100%;
}

.nav.navExpanded {
  width: 240px;
}

.navExpanded :global(.jc-wordmark) {
  opacity: 1;
  width: auto;
}

.navPanelSection {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  padding-bottom: var(--jc-size-1p25);
}

.gettingStartedSection {
  padding-bottom: 0;
}

.collapseItem {
  bottom: 0;
}

.collapseItem svg {
  transform: rotate(180deg);
  transition: all var(--jc-transition-duration) var(--jc-transition-function);
}

.navExpanded .collapseItem svg {
  transform: none;
}

.productTourActive {
  z-index: 1600;
}

@media (max-width: 1024px) {
  .nav.navExpanded,
  .navExpanded {
    width: 76px;
  }

  .navExpanded :global(.jc-wordmark) {
    margin-left: 0;
    opacity: 0;
    width: 0;
  }

  .collapseItem {
    display: none;
  }
}
</style>
