<template>
  <div :class="$style.container">
    <div :class="[$style.scrim, { [$style.scrimExpanded]: isLeftNavExpanded }]" />

    <ProductTourPopover
      :altText="stepData.imgAltText"
      :bodyText="stepData.bodyText"
      :headerText="stepData.headerText"
      :image="stepData.image"
      :placement="stepData.placement"
      :target="`#${$parent.$refs.navLinkLink.$el.id}`"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ProductTourPopover from '@/components/ProductTour/ProductTourPopover.vue';
import ProductTourSteps from '@/components/ProductTour/ProductTourSteps';

export default {
  name: 'NavPanelProductTour',

  components: {
    ProductTourPopover,
  },

  ProductTourSteps,

  props: {
    expanded: {
      type: Boolean,
    },
  },

  data() {
    return {
      showPopover: false,
    };
  },

  delayDuration: 100,

  computed: {
    ...mapState('ProductTourModule', [
      'productTourStepIndex',
    ]),

    isLeftNavExpanded() {
      return this.expanded && window.innerWidth > 1024;
    },

    stepData() {
      return this.$options.ProductTourSteps[this.productTourStepIndex];
    },
  },

  mounted() {
    this.transitionDelay();
  },

  methods: {
    transitionDelay() {
      this.showPopover = false;
      setTimeout(() => {
        this.showPopover = true;
      }, this.$options.delayDuration);
    },
  },
};
</script>

<style scoped module>
@value navWidth: 76px;
@value navWidthExpanded: 240px;

.container {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1601;
}

.scrim {
  background-color: var(--jc-scrim-background);
  bottom: 0;
  left: navWidth;
  position: fixed;
  top: 0;
  transition: all var(--jc-transition-duration) var(--jc-transition-function);
  width: calc(100% - navWidth);
}

.scrimExpanded {
  left: navWidthExpanded;
  width: calc(100% - navWidthExpanded);
}
</style>
