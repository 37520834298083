<template>
  <teleport to="body">
    <ModalStructure
      ref="modal"
      class="gotoModal"
      :showCloseButton="false"
      :showModal="showModal"
      @close="handleModalClosed"
    >
      <template v-slot:body>
        <div
          class="body"
          @mousemove="captureInput"
        >
          <InputSearch
            v-model="search"
            :autofocus="true"
            :focus="true"
            placeholder="Features, settings, pages, and more"
            @update:model-value="onInput"
          />
          <div class="searchResults">
            <div
              v-for="(type, typeIndex) in nonEmptyResultTypes"
              :key="typeIndex"
              class="resultSection"
            >
              <div
                class="header"
                :data-test-id="$testId('header-' + dashed(type.title))"
              >
                {{ type.title }}
              </div>
              <ul>
                <li
                  v-for="item in type.data.slice(0, type.maxVisible)"
                  :key="item.id"
                  :class="shouldHighlight(item) ? 'highlightedResult' : null"
                  :data-test-id="$testId('result-' + dashed(type.title))"
                  @click="onLinkClick(type, item)"
                  @mousemove="onMouseMove(item)"
                >
                  <div>
                    <component
                      :is="item.imageComponent"
                      class="icon"
                    />
                    <div
                      class="resultText"
                    >
                      <span
                        class="title"
                      >
                        {{ item.title }}
                      </span>
                      <span
                        v-if="item.subtitle"
                        class="subtitle"
                      >
                        <span class="divider">|</span>{{ item.subtitle }}
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer>
        <div
          class="footer"
          :data-test-id="$testId('footer')"
        >
          Not finding what you need?
          <!--
            tabindex=-1 prevents link from being tabbable so shift+tab
            does not lose focus on input.
          -->
          <a
            :href="supportHref"
            tabindex="-1"
            target="_blank"
            @click="onSupportFooterClicked"
          >
            Search our Help Center!
          </a>
        </div>
      </template>
    </ModalStructure>
  </teleport>
</template>

<script>
import {
  HeadingLarge,
  Icon,
  InputSearch,
  Link,
  ModalStructure,
  ParagraphBody,
  StatusBadge,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import { cloneDeep } from 'lodash';
import { featuresSearch } from './features';
import { markRaw } from 'vue';
import LocalStorageService from '@/utils/LocalStorageService';
import iconSupport from '@/img/icons/nav/icon-nav-support.svg';

const goToRecentSelections = 'jcGoToRecentSelections';
const maxRecentClickTracked = 10;
const maxRecordedInputs = 10;

export default {
  name: 'GoToModal',

  components: {
    HeadingLarge,
    Icon,
    InputSearch,
    Link,
    ModalStructure,
    ParagraphBody,
    StatusBadge,
  },

  props: {
    showModal: Boolean,
  },

  emits: ['update:showModal'],

  data: () => ({
    featuresData: [],
    lastCapturedInput: '',
    numRecordedInputs: 0,
    recentClicks: [],
    recentsData: [],
    search: '',
    selectedIndex: 0,
    visibleResultCount: 0,
  }),

  computed: {
    featuresType() {
      return {
        title: 'Pages',
        data: this.featuresData,
        maxVisible: 10,
      };
    },

    kbResults() {
      if (this.featuresData?.length === 0) {
        return [{
          title: this.search,
          subtitle: '',
          keys: '',
          imageComponent: iconSupport,
          externalUrl: this.supportHref,
        }];
      }

      return [];
    },

    knowledgeBaseType() {
      return {
        title: 'Search Help Center',
        data: this.kbResults,
        maxVisible: 1,
      };
    },

    nonEmptyResultTypes() {
      return this.resultTypes.filter((e) => e.data?.length > 0);
    },

    recentType() {
      return {
        title: 'Recent',
        data: this.recentsData,
        maxVisible: 3,
      };
    },

    resultTypes() {
      return [
        this.recentType,
        this.featuresType,
        this.knowledgeBaseType,
      ];
    },

    supportHref() {
      return this.search === ''
        ? 'https://jumpcloud.com/support'
        : `https://jumpcloud.com/support/search?search=${encodeURI(this.search)}`;
    },
  },

  watch: {
    showModal(newValue) {
      // Only intercept keypresses when the modal is visible
      if (newValue) {
        this.resetSearch();
        this.registerKeyDown();
      } else {
        this.unregisterKeyDown();
      }
    },
  },

  mounted() {
    // Only intercept keypresses when the modal is visible
    if (this.showModal) {
      this.registerKeyDown();
    }

    this.resetSearch();
  },

  created() {
    const { getItem } = LocalStorageService;
    this.recentClicks = JSON.parse(getItem(goToRecentSelections)) || [];
  },

  unmounted() {
    this.unregisterKeyDown();
  },

  methods: {
    addRecent(item) {
      const id = item.title + item.subtitle;

      this.recentClicks = this.recentClicks.filter(t => t !== id);
      this.recentClicks.unshift(id);
      this.recentClicks.splice(maxRecentClickTracked);

      this.updateVisibleResults(this.featuresData);

      LocalStorageService.setItem(goToRecentSelections, JSON.stringify(this.recentClicks));
    },

    captureInput() {
      // No need to record if the input is a substring of (or equal to)
      // our most recent capture
      if (this.lastCapturedInput.startsWith(this.search)) {
        return;
      }

      // Just in case this blows up segment events, we set a max for safety
      if (this.numRecordedInputs >= maxRecordedInputs) {
        return;
      }

      this.lastCapturedInput = this.search;
      this.numRecordedInputs += 1;

      UiEvents.triggerInputEntered({
        description: 'GoTo modal input entered',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'input',
        region: this.numRecordedInputs.toString(),
        value: this.featuresData?.length.toString(),
      });
    },

    dashed(text) {
      return text.replaceAll(' ', '-');
    },

    filterResultsForFeatureFlags(results) {
      return results.filter((e) => this.shouldIncludeResult(e.featureFlag));
    },

    handleModalClosed(fromClick) {
      // If the modal is closing for any reason other than a click, we want to
      // record that they navigated away without clicking.
      if (!fromClick) {
        UiEvents.triggerButtonClicked({
          description: 'GoTo modal exited without link click',
          displayLogic: this.search,
          page: 'GoTo Modal',
          section: 'n/a',
          text: '',
        });
      }

      this.$emit('update:showModal', false);
    },

    lookupByIndex(desiredIndex) {
      // Search through all the data types (recent, pages) in the order
      // they are displayed to figure out which item is at the provided index
      for (const resultType of this.resultTypes) {
        const found = resultType.data?.find(result => result.visibleIndex === desiredIndex);

        if (found !== undefined) {
          return {
            item: found,
            type: resultType,
          };
        }
      }

      return null;
    },

    // matchingRecents collects all recent click entries that actually
    // match a search result (i.e. the search term is returning the
    // recent entry).
    matchingRecents(featuresData) {
      const matching = [];

      this.recentClicks.forEach(recentId => {
        const result = featuresData.find((item) => recentId === item.title + item.subtitle);
        if (result !== undefined) {
          matching.push(cloneDeep(result));
        }
      });

      return matching;
    },

    onInput(input) {
      this.search = input;
      let featuresData = featuresSearch(this.search);
      featuresData = this.filterResultsForFeatureFlags(featuresData);
      featuresData = featuresData.map(item => ({
        ...item,
        imageComponent: item.imageComponent,
      }));
      this.selectedIndex = 0;

      this.updateVisibleResults(featuresData);
    },

    onKeyDown(event) {
      switch (event.key) {
        case 'ArrowUp':
          event.preventDefault();
          this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
          break;
        case 'ArrowDown':
          event.preventDefault();
          this.selectedIndex = Math.min(this.selectedIndex + 1, this.visibleResultCount - 1);
          break;
        case 'Tab':
          if (event.shiftKey) {
            this.selectedIndex = this.wrapIndex(this.selectedIndex - 1);
          } else {
            this.selectedIndex = this.wrapIndex(this.selectedIndex + 1);
          }
          break;
        case 'Enter': {
          const currentItem = this.lookupByIndex(this.selectedIndex);
          this.onLinkClick(currentItem.type, currentItem.item);
          break;
        }
        case 'Escape':
          // Prevent clearing the input before the modal visually disappears
          event.preventDefault();
          this.handleModalClosed();
          break;
        case 'Backspace':
          // If they are pressing backspace then it's possible they didn't find
          // what they were looking for, so let's capture this search string at
          // its fullest.
          this.captureInput();
          break;
        default:
      }
    },

    onLinkClick(type, item) {
      if (item.externalUrl) {
        // Slice off the leading # when calling programmatically
        window.open(item.externalUrl, '_blank');
      } else {
        this.$router.push(item.url);

        this.handleModalClosed(true);

        // Add this last so the UI doesn't change until after it is closed
        this.addRecent(item);
      }

      UiEvents.triggerLinkClicked({
        description: 'GoTo result clicked',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'results',
        region: type.title,
        text: `${item.title} - ${item.subtitle}`,
        toUrl: `${item.url || ''}${item.externalUrl || ''}`,
      });
    },

    onMouseMove(item) {
      if (this.selectedIndex !== item.visibleIndex) {
        this.selectedIndex = item.visibleIndex;
      }
    },

    onSupportFooterClicked() {
      UiEvents.triggerLinkClicked({
        description: 'GoTo support footer clicked',
        displayLogic: this.search,
        page: 'GoTo Modal',
        section: 'footer',
        region: 'footer',
        toUrl: this.supprtHref,
      });
    },

    registerKeyDown() {
      window.addEventListener('keydown', this.onKeyDown, true);
    },

    resetSearch() {
      this.selectedIndex = 0;
      this.search = '';
      this.onInput(this.search);
    },

    shouldHighlight(item) {
      return item.visibleIndex === this.selectedIndex;
    },

    shouldIncludeResult(featureFlag) {
      if (!featureFlag) {
        return true;
      }

      const hasFlag = this.$hasFeatureFlag(featureFlag);

      return featureFlag.startsWith('!') === !hasFlag;
    },

    unregisterKeyDown() {
      window.removeEventListener('keydown', this.onKeyDown, true);
    },

    updateVisibleResults(featuresData) {
      // Ensure featuresData is not reactive
      const nonReactiveFeaturesData = featuresData.slice(0, this.featuresType.maxVisible);

      // Update recent data BEFORE pruning down featuresData to make sure
      // recent entries for non-visible results are shown too
      const matchingRecents = this.matchingRecents(featuresData);
      const slicedRecents = matchingRecents.slice(0, this.recentType.maxVisible);
      this.recentsData = markRaw(slicedRecents);
      this.featuresData = nonReactiveFeaturesData;

      // Now go through and assign a "visibleIndex" to each entry for easy arrow up/down navigation
      let totalIndex = 0;
      this.resultTypes?.forEach(resultType => {
        resultType.data?.forEach((result, index, data) => {
          // eslint-disable-next-line no-param-reassign
          data[index].visibleIndex = totalIndex;
          totalIndex += 1;
        });
      });
      this.visibleResultCount = totalIndex;
    },

    wrapIndex(newIndex) {
      return (newIndex + this.visibleResultCount) % this.visibleResultCount;
    },
  },
};
</script>

<style scoped>
.body ::v-deep(input) {
  font-size: var(--jcBodyLarge);
}

.divider {
  color: var(--jc-border-color);
  padding-left: var(--jc-input-padding-side);
  padding-right: var(--jc-input-padding-side);
}

.footer {
  font-size: var(--jcBodySmall);
  font-weight: 400;
}

.footer a {
  font-weight: 600;
}

.gotoModal ::v-deep(header) {
  display: none;
}

.gotoModal ::v-deep(.body) {
  padding-top: var(--modal-padding);
}

.gotoModal ::v-deep(div[class*="ModalContainer__modal"]) {
  margin: 60px 0;
  max-height: calc(100vh - 60px);
}

.gotoModal ::v-deep div[class*="ModalContentStructure__modalContent"] {
  --modal-padding: var(--jc-spacer);

  grid-gap: 0;
}

.gotoModal ::v-deep footer[class*="ModalContentStructure__footer"] {
  border-top: var(--jc-border);
  padding-left: var(--jc-spacer-medium);
}

.gotoModal {
  align-items: flex-start!important;  /* overwrite default modal alignment */

  --modal-width: 500px;
}

.header {
  align-items: flex-end;
  color: var(--jc-text-color-light);
  display: flex;
  font-size: var(--jcBodySmall);
  font-weight: 600;
  height: var(--jcHeadingLarge);
  margin: 0;
  margin-bottom: var(--jc-spacer-small);
  padding-left: var(--jc-spacer-small);
}

.highlightedResult {
  background-color: var(--jc-color-secondary-fill-hover);
  border: var(--jc-border);
  border-radius: var(--jc-border-radius);
  cursor: pointer;
  transition: background-color var(--jc-transition-duration-fast) var(--jc-transition-function),
              border-color var(--jc-transition-duration-fast) var(--jc-transition-function);
}

.icon.icon {
  fill: currentColor;
  height: var(--jcHeadingMedium);
  margin-right: var(--jc-input-padding-side);
  vertical-align: middle;
  width: var(--jcHeadingMedium);
}

li {
  background-color: white;
  border: var(--jc-border-width) solid transparent;
  border-radius: var(--jc-border-radius);
  gap: var(--jc-spacer-x-small);
  padding: var(--jc-spacer-small);
  transition: background-color var(--jc-transition-duration-fast) var(--jc-transition-function),
              border-color var(--jc-transition-duration-fast) var (--jc-transition-function);
}

.resultSection {
  margin-bottom: var(--jc-spacer);
  margin-top: var(--jc-spacer-small);
}

.resultText {
  display: inline;
  vertical-align: middle;
}

.searchResults {
  width: 100%;
}

.subtitle {
  color: var(--jc-text-color-light);
  font-size: var(--jcBody);
  font-weight: 400;
}

.title {
  color: var(--jc-text-color);
  display: inline-block;
  font: var(--jc-font);
  font-size: var(--jcBody);
  font-weight: 600;
}

ul {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer-x-small);
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
