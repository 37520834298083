<template>
  <ProductTourModal />
</template>

<script>
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import { eventBus } from '@jumpcloud-ap/event-bus';
import { mapActions, mapState } from 'vuex';
import ProductTourModal from '@/components/ProductTour/ProductTourModal.vue';
import ProductTourSteps from '@/components/ProductTour/ProductTourSteps';

/**
 * This component exists to subscribe to launch events on the event bus.
 * If this didn't depend on the $router, it could be moved to the Vuex module
 * Until then, I need this headless component to have access to the router
 */
export default {
  name: 'ProductTourController',

  components: {
    ProductTourModal,
  },

  computed: {
    ...mapState('ProductTourModule', [
      'productTourStepIndex',
    ]),
  },

  mounted() {
    eventBus.subscribe('product-tour:launch', this.launchProductTour);
  },

  beforeUnmount() {
    eventBus.unsubscribe('product-tour:launch', this.launchProductTour);
  },

  methods: {
    ...mapActions('ProductTourModule', [
      'setShowProductTour',
    ]),

    launchProductTour(eventDetails) {
      UiEvents.triggerButtonClicked({
        description: 'Launch Product Tour',
        page: this.$page,
        section: 'n/a',
        text: 'Product Tour',
        value: this.productTourStepIndex,
        ...eventDetails,
      });

      if (this.productTourStepIndex > 0) {
        this.setShowProductTour(true);
        // Start from the last viewed step
        this.$router
          .push(ProductTourSteps[this.productTourStepIndex].navPanelLink.href)
          .catch(() => {});
      } else {
        eventBus.publish('product-tour-modal:open');
      }
    },
  },
};
</script>
