<template>
  <div>
    <NavPanelLink
      class="button"
      isButton
      :item="gotoItem"
      :panelExpanded="!isNavCollapsed"
      secondaryColor
      @click="onClick"
    />
    <GoToModal
      v-model:showModal="showModal"
    />
  </div>
</template>

<script>
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import GoToModal from '@/components/GoTo/GoToModal.vue';
import IconGoTo from '@/img/icons/nav/icon-arrow-right.svg';
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

export default {
  name: 'GoToButton',

  components: {
    GoToModal,
    NavPanelLink,
  },

  props: {
    isNavCollapsed: Boolean,
    isShortcutDisabled: Boolean,
  },

  data: () => ({
    showModal: false,
  }),

  computed: {
    goToButtonText() {
      return 'Go To (⌘/Ctrl + K)';
    },

    gotoItem() {
      return new NavPanelLinkListItem({
        imageComponent: IconGoTo,
        title: this.goToButtonText,
      });
    },
  },

  created() {
    this.registerKeyDown();
  },

  unmounted() {
    this.unregisterKeyDown();
  },

  methods: {
    closeGoTo() {
      this.showModal = false;
    },

    onClick() {
      UiEvents.triggerButtonClicked({
        description: 'GoTo launched',
        page: this.$page,
        section: 'body',
        region: 'nav-panel',
        text: this.goToButtonText,
      });

      this.openGoTo();
    },

    onKeyDown(event) {
      if (this.isShortcutDisabled) {
        return;
      }

      // Ctrl+k is a shortcut to open and close the modal
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();

        if (this.showModal) {
          this.closeGoTo();
        } else {
          UiEvents.triggerButtonClicked({
            description: 'GoTo launched via keyboard shortcut',
            page: this.$page,
            section: 'body',
            region: 'nav-panel',
            text: this.goToButtonText,
          });

          this.openGoTo();
        }
      }
    },

    openGoTo() {
      this.showModal = true;
    },

    registerKeyDown() {
      window.addEventListener('keydown', this.onKeyDown, true);
    },

    unregisterKeyDown() {
      window.removeEventListener('keydown', this.onKeyDown, true);
    },
  },
};
</script>

<style scoped>
:deep(button) {
  cursor: pointer;
}

.icon.icon {
  fill: currentColor;
  flex: 0 0 auto;
  height: 1em;
  width: 1em;
}

.navLinkText {
  display: inline-block;
  flex: 1 1 auto;
  font-size: var(--jcBodySmall);
  font-weight: 500;
  line-height: 2;
  overflow: hidden;
  white-space: nowrap;
}
</style>
