import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export interface SettingsResponse {
  SUPPORT_LEVEL?: string | '',
}

class SettingsApi {
  private baseUrl = '/api/settings';
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getGlobalSettings(): Promise<SettingsResponse> {
    const response = await this.axiosInstance.get<SettingsResponse>(this.baseUrl);

    return response?.data;
  }
}

export default new SettingsApi();
