<template>
  <div
    v-if="showDaysLeft"
    :class="{
      [$style.daysLeft]: true,
      [$style.daysLeftExpanded]: expanded
    }"
  >
    <span :class="$style.daysLeftCount">{{ daysLeft }}</span>
    <div :class="$style.daysLeftText">
      days
      <span>left</span>
    </div>
  </div>
</template>

<script>
import { DateUtil } from '@jumpcloud-ap/date-util';

export default {
  name: 'DaysRemainingBadge',

  props: {
    comparisonDate: {
      type: [String, Date],
      required: true,
    },
    expanded: Boolean,
    trialLength: {
      type: Number,
      required: true,
    },
  },
  computed: {
    daysLeft() {
      return this.trialLength - DateUtil.daysRemaining('today', this.comparisonDate);
    },
    showDaysLeft() {
      return this.daysLeft >= 0;
    },
  },
};
</script>

<style module>
.daysLeft {
  font-size: 10px;
  line-height: 1;
  text-align: center;
}

.daysLeftCount {
  background: var(--jcPrimary);
  border-radius: 4px;
  color: var(--jcGrey900);
  display: inline-block;
  margin-bottom: 0.25rem;
  padding: 0.25em;
}

.daysLeftText {
  flex: 0 0 100%;
  line-height: 1.25;
}
</style>
