import { type AxiosInstance, createJcApiAxiosInstance } from '@jumpcloud-ap/axios-setup';

export interface OrganizationDetailsResponse {
  created?: string,
}

export class OrganizationsApi {
  private baseUrl = '/api/organizations';

  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = createJcApiAxiosInstance();
  }

  async getOrganizationDetails(id: string): Promise<OrganizationDetailsResponse> {
    try {
      const fields = [
        'created',
      ];

      const response = await this.axiosInstance.get<OrganizationDetailsResponse>(`${this.baseUrl}/${id}?fields=${fields.join(' ')}`);

      return response.data;
    } catch (err: unknown) {
      return {};
    }
  }
}

export default new OrganizationsApi();
