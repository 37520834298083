/**
 * Each route must define a `component`.
 * We use a placeholder component here to stop warnings/errors
 * because the actual components are in other MFEs and aren't accessible to sidenav.
 */

import { createRouter, createWebHashHistory } from 'vue-router';
import PlaceholderComponent from '@/components/PlaceholderComponent.vue';
import type { RouteRecordRaw } from 'vue-router';

export const router = createRouter({
  history: createWebHashHistory('/'),
  routes: [
    /**
     * This route exists to suppress router warnings about routes
     * from other MFEs not being defined here.
     * This route must be the last route defined.
     */
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: PlaceholderComponent,
    },
  ] as Array<RouteRecordRaw>,
});
