<template>
  <div :class="$style.container">
    <Link
      :class="$style.noUnderline"
      :to="prevNavPanelLink"
    >
      <Button
        :class="{ [$style.hidden]: firstStep }"
        :data-test-id="$testId('back')"
        hasOutline
        :isDisabled="firstStep"
        :text="$options.buttonLabels.back"
        @click="handleNextPage(false)"
      />
    </Link>

    <BreadcrumbLinks
      v-if="!isMobile"
      :class="$style.breadcrumbs"
      :currentStep="productTourStepIndex"
      :links="productTourLinks"
      @click="handleBreadcrumb"
    />

    <Link
      v-if="!lastStep"
      :class="$style.noUnderline"
      :to="nextNavPanelLink"
    >
      <Button
        :data-test-id="$testId('next')"
        :text="$options.buttonLabels.next"
        @click="handleNextPage(true)"
      />
    </Link>

    <Button
      v-else
      :data-test-id="$testId('done')"
      :text="$options.buttonLabels.done"
      @click="handleComplete"
    />
  </div>
</template>

<script>
import { Button, Link } from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import { eventBus } from '@jumpcloud-ap/event-bus';
import { mapActions, mapState } from 'vuex';
import { removeHash } from '@/utils/removeHash';
import BreadcrumbLinks from '@/components/ProductTour/BreadcrumbLinks.vue';
import ProductTourSteps, { stepIndexes } from './ProductTourSteps';

const buttonLabels = {
  back: 'Back',
  done: 'Done',
  next: 'Next',
};

export default {
  name: 'ProductTourPagination',

  ProductTourSteps,
  buttonLabels,

  components: {
    BreadcrumbLinks,
    Button,
    Link,
  },

  computed: {
    ...mapState('ProductTourModule', [
      'productTourStepIndex',
    ]),

    firstStep() {
      return this.productTourStepIndex === stepIndexes.modal;
    },

    isMobile() {
      return window.innerWidth <= 768;
    },

    lastStep() {
      return this.productTourStepIndex === this.$options.ProductTourSteps.length - 1;
    },

    nextNavPanelLink() {
      const nextProductTourStep = this.$options.ProductTourSteps[this.productTourStepIndex + 1];

      return removeHash(nextProductTourStep?.navPanelLink?.href);
    },

    prevNavPanelLink() {
      if (this.productTourStepIndex <= 0) {
        return '';
      }

      const { href } = this.$options.ProductTourSteps[this.productTourStepIndex - 1].navPanelLink;

      return removeHash(href);
    },

    productTourLinks() {
      return this.$options.ProductTourSteps.map(step => step.navPanelLink);
    },
  },

  methods: {
    ...mapActions('ProductTourModule', [
      'setProductTourStep',
      'setShowProductTour',
    ]),

    handleBreadcrumb(index) {
      // Relaunch modal
      if (index === 0) {
        this.handleInitialStep();
      } else if (this.productTourStepIndex === 0) {
        this.handleShowProductTour();
      }

      this.setProductTourStep(index);
    },

    handleComplete() {
      UiEvents.triggerButtonClicked({
        description: 'clicked button to finish product tour',
        page: this.$page,
        section: 'footer',
        region: 'Product Tour Popover',
        text: 'Done',
      });

      this.setShowProductTour(false);
      this.setProductTourStep(stepIndexes.modal);

      // Route to Get Started page when done
      this.$router
        .push(removeHash(this.$options.ProductTourSteps[stepIndexes.modal].navPanelLink.href))
        .catch(() => {});
    },

    handleInitialStep() {
      this.setShowProductTour(false);
      eventBus.publish('product-tour-modal:open');
    },

    handleNextPage(shouldIncrementIndex) {
      // increment/decrement tour step
      const increment = shouldIncrementIndex ? 1 : -1;

      // Close modal and open popover when we click the next button from step 0
      if (this.productTourStepIndex === 0 && increment === 1) {
        this.handleShowProductTour();
      } else if (this.productTourStepIndex === 1 && increment === -1) {
        // Re-open modal if we click the previous button on step 1
        this.handleInitialStep();
      }

      this.setProductTourStep(this.productTourStepIndex + increment);
    },

    handleShowProductTour() {
      // Close modal/Open popover
      this.setShowProductTour(true);
      eventBus.publish('product-tour-modal:close');
    },
  },
};
</script>

<style module scoped>
  .container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
  }

  .hidden {
    visibility: hidden;
  }

  .breadcrumbs {
    padding: 0 var(--jc-spacer-medium);
    width: 100%;
  }

  .noUnderline.noUnderline.noUnderline,
  .noUnderline.noUnderline.noUnderline:hover,
  .noUnderline.noUnderline.noUnderline:active {
    border-bottom: none;
  }
</style>
