<!--
  This component does nothing and is not used.
  It only exists to meet a technical Vue 3 router requirement
  and stop warnings and errors as the user navigates.
-->
<template>
  <div />
</template>

<script>
export default {
  name: 'PlaceholderComponent',
};
</script>
