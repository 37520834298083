<template>
  <ModalConfirmation
    :showModal="shouldShowModal"
    title="It looks like you're using a content blocker!"
  >
    <template v-slot:body>
      <ParagraphBody>
        We've detected that you're using a content blocking plugin for your browser.
      </ParagraphBody>
      <ParagraphBody>
        To access Live Chat, go to your content blocking plugin
        and add jumpcloud.com as an exception.
      </ParagraphBody>
    </template>
    <template v-slot:footer>
      <ButtonGroup
        :isFullWidth="false"
      >
        <Button
          text="ok, got it"
          textCase="inherit"
          @click="handleOkClick"
        />
      </ButtonGroup>
    </template>
  </ModalConfirmation>
</template>

<script>
import {
  Button, ButtonGroup, ModalConfirmation, ParagraphBody,
} from '@jumpcloud/ui-components';

export default {
  name: 'IntercomChatContentBlockerModal',

  components: {
    Button,
    ButtonGroup,
    ModalConfirmation,
    ParagraphBody,
  },

  props: {
    shouldShowModal: Boolean,
  },

  emits: ['update:shouldShowModal'],

  methods: {
    handleOkClick() {
      this.$emit('update:shouldShowModal', false);
    },
  },
};
</script>
