<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.disabled]: disabled,
      [$style.navExpanded]: panelExpanded,
    }"
  >
    <button
      v-if="isButton"
      key="nav-link-button"
      ref="navLinkButton"
      :class="itemClasses"
      :disabled="isDisabled"
      :title="item.title"
      @click="handleClick"
    >
      <component
        :is="item.imageComponent"
        :class="$style.icon"
      />
      <transition v-bind="transitionProps">
        <span
          v-show="panelExpanded"
          :class="$style.navLinkText"
        >
          {{ item.title }}
        </span>
      </transition>
      <slot />
    </button>
    <Link
      v-else
      :id="`NavPanelLink__navLink-${getLinkId}`"
      key="nav-link-link"
      ref="navLinkLink"
      :class="itemClasses"
      :data-test-id="$testId('navLink')"
      :disabled="isDisabled"
      :title="item.title"
      :to="itemTo"
      @click="trackNavClicked(item.href, item.title)"
    >
      <component
        :is="item.imageComponent"
        :class="$style.icon"
      />
      <transition v-bind="transitionProps">
        <span
          v-show="panelExpanded"
          :class="$style.navLinkText"
        >
          {{ item.title }}
        </span>
      </transition>
      <transition v-bind="transitionProps">
        <span
          v-if="item.isNew"
          v-show="panelExpanded"
          :class="$style.newBadge"
        >
          new
        </span>
      </transition>
      <slot name="default" />
    </Link>
    <slot name="popover" />
  </div>
</template>

<script>
import {
  Link,
} from '@jumpcloud/ui-components';
import { UiEvents } from '@jumpcloud/ui-events-vue3';
import { mapState } from 'vuex';
import { removeHash } from '@/utils/removeHash';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

export default {
  name: 'NavPanelLink',

  components: {
    Link,
  },

  props: {
    active: Boolean,
    activeLink: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
    highlightProductTour: Boolean,
    isButton: Boolean,
    item: {
      type: NavPanelLinkListItem,
      required: true,
    },
    panelExpanded: {
      type: Boolean,
      required: true,
    },
    secondaryColor: Boolean,
  },

  emits: ['click'],

  computed: {
    ...mapState('ProductTourModule', [
      'showProductTour',
    ]),

    getLinkId() {
      // html id's shouldn't have whitespace
      return this.item?.title
        ? this.item.title.replace(/\s+/g, '_')
        : '';
    },

    isDisabled() {
      return this.showProductTour;
    },

    itemClasses() {
      const { $style } = this;

      return [
        $style.navLink,
        {
          [$style.active]: this.active,
          [$style.secondary]: this.secondaryColor || (
            this.showProductTour && !this.highlightProductTour
          ),
        },
      ];
    },

    itemTo() {
      const { href = '' } = this.item;

      return removeHash(href);
    },

    transitionProps() {
      return {
        enterClass: this.$style.opacityEnter,
        enterActiveClass: this.$style.opacityTransition,
        leaveActiveClass: this.$style.opacityTransition,
        leaveToClass: this.$style.opacityLeaveTo,
      };
    },
  },

  watch: {
    async active() {
      if (!this.active) {
        // https://jumpcloud.atlassian.net/browse/PRO-1225
        // @todo re-test this once all routes are converted and the dual router is removed.
        // this watcher may not be necessary afterwards

        await this.$nextTick();
        const navLink = this.$refs.navLinkButton ?? this.$refs.navLinkLink;
        if (document.activeElement === navLink) {
          navLink.blur();
        }
      }
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },

    trackNavClicked(href, toTitle) {
      const toHref = href.replace('#/', '');
      UiEvents.triggerNavItemClicked({
        fromUrl: this.activeLink,
        logoClicked: false,
        toUrl: toHref,
        text: toTitle,
        description: `${toTitle} nav link clicked`,
        page: this.$page,
      });
    },
  },
};
</script>

<style scoped module>
@value activeDotSize: 5px;
@value navHighlight: rgba(169, 190, 206, 0.16);

.container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  position: relative;
}

.disabled {
  pointer-events: none;
}

.tourDisabled {
  color: var(--jc-color-neutral-text-descriptive);
}

.newBadge {
  align-items: center;
  background: var(--jcWarning100);
  border-radius: 4px;
  color: var(--jc-text-color-dark);
  display: flex;
  font-size: 8px;
  font-weight: 700;
  height: 14px;
  padding: 2px 4px;
  position: absolute;
  right: 8px;
  text-transform: uppercase;
}

.navLink.navLink.navLink {
  align-items: center;
  background: none;
  border: 0;
  border-radius: 100px;
  color: var(--jc-color-surface-light);
  display: flex;
  justify-content: center;
  margin: 0;
  max-width: inherit;
  min-height: 32px;
  opacity: 0.9;
  overflow: hidden;
  padding: 4px var(--navPanelPadding);
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: all 200ms ease-in-out;
  width: 100%;
}

.navLink.navLink:first-of-type {
  margin-top: 0;
}

.secondary {
  color: var(--jc-color-disabled-text);
}

.secondaryProductTour {
  color: var(--jc-color-surface-light);
}

.navLink.navLink.navLink:hover,
.navLink.navLink.navLink:focus,
.active.navLink.navLink.navLink {
  background-color: navHighlight;
  border: 0;
  box-shadow: none;
  color: var(--jc-color-surface-light);
  opacity: 1;
}

.active::before {
  background: var(--jc-button-primary);
  border-radius: activeDotSize;
  content: "";
  height: activeDotSize;
  left: 8px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: activeDotSize;
}

.icon.icon {
  fill: currentColor;
  flex: 0 0 auto;
  font-size: 14px;
  height: 1em;
  transition: margin-right 200ms ease-in-out;
  width: 1em;
}

.navExpanded .icon {
  margin-right: 16px;
}

.active .icon {
  opacity: 1;
}

.navLink.navLink:active .icon {
  opacity: 1;
}

.navLinkText {
  display: inline-block;
  flex: 1 1 auto;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  overflow: hidden;
  white-space: nowrap;
}

.opacityEnter,
.opacityLeaveTo {
  opacity: 0;
}

.opacityTransition {
  transition: opacity 200ms ease-in-out;
}

.showProductTour {
  color: var(--jc-color-surface-light);
}

[disabled="disabled"] {
  pointer-events: none;
}

@media (max-width: 1024px) {
  .navExpanded .navLinkText {
    flex: 0 0 0;
    opacity: 0;
    width: 0;
  }

  .navExpanded .icon {
    margin-right: 0;
  }

  .newBadge {
    opacity: 0;
  }
}
</style>
