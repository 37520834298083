<template>
  <NavPanelLink
    :active="isActive"
    :activeLink="activeLink"
    :item="item"
    :panelExpanded="panelExpanded"
  />
</template>

<script>
import NavPanelLink from '@/components/NavPanelLink.vue';
import NavPanelLinkListItem from '@/components/NavPanelLinkListItem';

export default {
  name: 'GetStartedLink',

  components: {
    NavPanelLink,
  },

  props: {
    activeLink: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: NavPanelLinkListItem,
      required: true,
    },
    panelExpanded: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
